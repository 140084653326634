import React from "react"

import { StyledBanner } from "./Banner.styled"

const Banner = ({ title, image }) => {
  return (
    <StyledBanner>
      <img loading="lazy" alt="banner" src={image} />
      <div className="content">
        <h3>{title}</h3>
      </div>
    </StyledBanner>
  )
}

export default Banner
