import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Card from "../components/Card/Card"
import CardGrid from "../components/CardGrid/CardGrid"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"
import Banner from "../components/Banner/Banner"

import imageBanner from "../assets/images/banner_bg_4.png"

export const query = graphql`
  query {
    allContentfulNieruchomosc(sort: { fields: publishedDate, order: DESC }) {
      edges {
        node {
          title
          slug
          city
          category
          image {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            }
            file {
              fileName
            }
          }
        }
      }
    }
  }
`

const NieruchomosciPage = props => {
  return (
    <Layout>
      <SEO title="Zrealizowane projekty" />
      <div style={{ height: "60px" }} />
      <Banner title="NASZE PROJEKTY" image={imageBanner} />
      <ContentWrapper>
        <CardGrid>
          {props.data.allContentfulNieruchomosc.edges.map((edge, index) => {
            // destructing
            const { slug, image, city, title } = edge.node

            return (
              <Card
                key={index}
                to={`/oferty/${slug}`}
                title={title}
                imgSrc={image && image.fluid}
                imgAlt={image && image.file.fileName}
                category={city}
              />
            )
          })}
        </CardGrid>
      </ContentWrapper>
    </Layout>
  )
}

export default NieruchomosciPage
