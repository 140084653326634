import styled from "styled-components"

export const StyledBanner = styled.div`
  position: relative;
  height: 200px;

  margin-bottom: 50px;

  ${({ theme }) => theme.media.tablet} {
    margin-bottom: 100px;
  }

  img {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    height: 200px;
    width: 100%;
    z-index: -1;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    width: 100%;
    padding: 10px;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 1;

    h3 {
      font-size: 3rem;
      font-weight: 400;
      letter-spacing: 4px;
    }
  }
`
